<template>
  <div id="app">
    <Banner />
    <div class="containerBodyApp">
      <Export_document />
      <Side_bar />
    </div>
  </div>
</template>

<script>
import Banner from "./components/Banner.vue";
import Side_bar from "./components/Side_bar.vue";
import Export_document from "./views/Export_document.vue";

export default {
  name: "App",
  components: {
    Banner,
    Export_document,
    Side_bar,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import "./assets/css/modal.css";
@import "./assets/css/tooltip.css";

body,
#app {
  // background-color: #f5f6f8;
  background-color: #ffffff;
  width: 100%;
  margin: 0px;
  font-family: "Inter";
}

/* GLOBAL CCS */
.white {
  color: #ffffff !important;
}

.green {
  color: #369e3b !important;
}

.black {
  color: #000000 !important;
}

.greyLight {
  color: #6c6c6c !important;
}

.grey {
  color: #6b7280 !important;
}

.black {
  color: #000000 !important;
}

.mauto {
  margin: auto;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt40 {
  margin-top: 40px;
}

.mr10 {
  margin-right: 10px;
}

.pd5 {
  padding: 5px;
}

.pd710 {
  padding: 7px 10px;
}
.pf10 {
  padding-left: 10px;
}

.pd20H {
  padding: 0px 20px;
}

.pd10Top {
  padding-top: 10px;
}

.pd3Left {
  padding-left: 3px;
}

.radius5 {
  border-radius: 5px;
}

.w175 {
  width: 176px;
}

.h30 {
  height: 30px;
}

.bgWhite {
  // background-color: #fdfdfd;
  background-color: rgb(253, 253, 253, 0.98);
}

.bgGrey {
  background-color: #ebeff2;
}
.bgDarkGrey {
  background-color: #a1a0a0;
}
.bgGreen {
  // background-color: #369e3b;
  background-color: rgb(54, 158, 59, 0.98);
}
.bgBlue {
  background-color: #003b51;
}
.bgLightGrey {
  background-color: #003b5175;
}

span {
  font-size: 13px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
}

.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

.fw300 {
  font-weight: 300;
}

.redirectButton {
  text-decoration: none;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
}

.containerBodyApp {
  display: flex;
}
</style>
