<template>
  <div style="background-color: white">
    <div class="containerBanner">
      <div class="container subContainer">
        <div class="containerLeft">
          <a
            href="https://sinao.fr"
            target="_blank"
            v-tooltip.right="
              'Sinao Link est un portail client sécurisé visant <br /> à simplifier vos échanges avec vos fournisseurs <br /> (paiement rapide et comptabilité automatisée).'
            "
          >
            <img
              v-if="this.windowWidth > 700"
              class="logo small"
              src="../assets/logo-black.svg"
            />
            <img v-else class="logo" src="../assets/icon.png" />

            <span v-if="this.windowWidth > 700" class="link pd3Left black"
              >Link</span
            >
          </a>

          <span class="black pf10" style="display: none">
            Gérer son activité n'a jamais été aussi simple
          </span>
        </div>

        <div class="containerLeft">
          <!-- <i class="fa fa-lock green" aria-hidden="true"></i> -->
          <img
            src="../assets/icons/lock.svg"
            alt=""
            srcset=""
            style="height: 22px"
          />
          <span class="grey pf10" style="font-size: 12px">Espace sécurisé</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner_sinao",
  props: {},
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  created() {},
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
.container {
  width: 100%;
  margin: auto;
  padding-left: 25%;
  padding-right: 25%;
}

@media screen and (max-width: 1800px) {
  .container {
    width: 100%;
    margin: auto;
    padding-left: 15%;
    padding-right: 15%;
  }
}

.containerBanner {
  width: calc(100% - 200px);
  height: 40px;
  background-color: #ffffff;
}

.subContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerLeft {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  height: 20px;
  width: auto;
}

.small {
  height: 16px;
}

i {
  width: 14px;
  height: 14px;
}
.link {
  font-size: 12px;
  font-weight: normal;
  font-family: "Inter";
  text-decoration: none;
}

@media screen and (max-width: 630px) {
  .containerBanner {
    width: calc(100% - 115px);
  }

  .container {
    padding-left: 5%;
    padding-right: 5%;
  }
}
</style>
