<template>
  <div class="info_login-container">
    <div class="info-container">
      <img
        class="logo"
        src="../assets/logo-black.svg"
        style="margin-bottom: 20px"
      />
      <br />

      <p style="color: #6c6c6c; font-size: 12px; font-family: 'Inter'">
        Automatisez l’import et la saisie des factures de vos fournisseurs de
        confiance. Ou importez simplement cette facture en un clic.
      </p>

      <br />

      <a
        class="link"
        href="https://www.sinao.fr/fonctionnalites/gestion-des-depenses"
        target="_blank"
      >
        Découvrir Sinao
        <i class="fa-solid fa-arrow-right" style="color: #369e3b"></i>
      </a>
    </div>

    <hr class="separator_login" />

    <div class="login-container">
      <div class="title-container">
        <!-- <h4 class="mb-4">Connexion</h4> -->
        <h4>Connexion</h4>
        <p>Connectez-vous à votre compte Sinao</p>
        <vs-alert type="warning" :active="showLoginError" color="danger">
          Les données de connexion ne sont pas correctes.
        </vs-alert>
      </div>

      <div class="input-container">
        <vs-input
          icon-no-border
          v-model="email"
          v-validate="'required|email'"
          data-vv-validate-on="blur"
          name="email"
          icon="icon icon-mail"
          label-placeholder="Email"
          icon-pack="feather"
          @keypress.enter="login"
        />
        <span v-show="errors.has('email')" class="text-danger text-sm">{{
          errors.first("email")
        }}</span>

        <vs-input
          ref="password"
          v-model="password"
          v-validate="'required'"
          icon-pack="feather"
          icon="icon-lock"
          icon-no-border
          type="password"
          data-vv-validate-on="blur"
          name="password"
          label-placeholder="Mot de passe"
          placeholder="Mot de passe"
          class="w-full mt-6 no-icon-border"
          @keypress.enter="login"
        />
        <span v-show="errors.has('password')" class="text-danger text-sm">{{
          errors.first("password")
        }}</span>
      </div>

      <div class="btn-container">
        <button
          ref="submit_login"
          class="w-full btn vs-con-loading__container"
          :disabled="loading"
          @click="login"
        >
          Connexion
        </button>

        <!-- <button
          class="w-full btn btn-inverse"
          type="border"
          @click="createAccount"
        >
          Créer un compte
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";

const dict_fr = {
  custom: {
    email: {
      required: "Veuillez entrer l'email",
      email: "L'email n'est pas valide",
    },
    password: {
      required: "Veuillez entrer un mot de passe",
    },
  },
};

Validator.localize("fr", dict_fr);
Validator.localize("en", dict_fr);

export default {
  name: "Login_Sinao",
  props: [],
  data() {
    return {
      email: "",
      password: "",
      showLoginError: false,
      loading: false,
    };
  },
  methods: {
    createAccount() {
      window.location.href = "https://www.sinao.fr/inscription";
    },
    async login() {
      const { email, password } = this;
      if (email && password) {
        const isFormValid = await this.$validator.validateAll();

        if (isFormValid) {
          this.loading = true;
          this.$vs.loading({
            type: "default",
            color: "white",
            background: "#3367D6",
            container: this.$refs.submit_login,
            scale: 0.5,
          });

          try {
            const response = await this.$store.dispatch("login", {
              email,
              password,
            });

            if (response != null) {
              await this.$store.dispatch("getUser").catch((error) => {
                console.log(error);
              });

              const cookieArray = document.cookie.split("; ");
              for (const cookie of cookieArray) {
                const [name, value] = cookie.split("=");

                if (name == "token" || name == "ken") {
                  this.$store.dispatch("setBearer", { bearer: value });
                  break;
                }
              }

              this.$store.dispatch("changeStep", { step: 2 });
            } else {
              throw new Error("login failed 1");
            }
          } catch (error) {
            this.showLoginError = true;
            this.password = "";
          }

          this.loading = false;
          this.$vs.loading.close(this.$refs.submit_login);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.mb-4 {
  margin-bottom: 14px !important;
}

.info_login-container {
  display: flex;
  margin-top: 20px;
}

.info-container {
  width: fit-content;
  text-align: left;
  margin: auto;
  width: 50%;
  padding-left: 30px;
  padding-right: 30px;
}

.link {
  text-decoration: none;
}

.link:link,
.link:visited {
  color: #369e3b;
}

.separator_login {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #6c6c6c;
}

.points-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.info-point {
  color: black;
  font-family: "Inter";
  font-size: 14px;
}

.info-point:not(:last-child) {
  margin-bottom: 12px;
}

.login-container {
  min-width: 435px;
  margin: auto;
  margin-top: 0px !important;

  padding-left: 30px;
  padding-right: 30px;
}

.title-container,
.input-container {
  margin-bottom: 28px;
}

.title-container {
  text-align: left;
}

.input-container {
  text-align: left;
}

.vs-input {
  margin-top: 25px !important;
  font-size: 14px !important;
}

input {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.vs-input--input.hasIcon {
  padding: 10px 14px 10px 32px !important;
}
.vs-input--input.normal {
  font-size: 14px !important;
  height: 34px !important;
}

.vs-input--input {
  color: inherit;
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}
.vs-con-input-label {
  width: 100% !important;
}
.vs-input--input:focus.vs-input--placeholder {
  top: 5px !important;
}

.vs-input--placeholder {
  height: 34px !important;
}

.input-span-placeholder {
  font-size: 13px !important;
  line-height: 2;
}
input::-webkit-input-placeholder {
  line-height: normal !important;
}

.vuesax-app-is-ltr .vs-input--icon {
  left: 8px !important;
  top: 8px !important;
}
.icon-lock:before {
  display: block;
  width: 15px !important;
  height: 15px !important;
}

.btn {
  background-color: #003b51;
  padding: 11px 28px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out,
    color 0.4s ease-in-out;
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff;
}
.btn:hover {
  background-color: rgba(0, 59, 81, 0.71);
}
.w-full {
  width: 100% !important;
}
.mb-3 {
  margin-bottom: 10.5px !important;
}
.btn-inverse {
  background-color: #fff;
  color: #003b51;
  border: 1px solid #003b51;
  height: 38px;
}
.btn-inverse:hover {
  background-color: rgba(19, 126, 200, 0.11);
  box-shadow: none;
  color: #003b51;
}

.text-danger {
  color: rgba(var(--vs-danger), 1) !important;
  font-size: 13px !important;
}
</style>
