<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" :class="{ modal_login: step_connect == 1 }">
        <header class="modal-header">
          <div class="containerHeader">
            <h1 class="titleModal header_login white">Importer dans Sinao</h1>
            <i @click="close" class="fa-solid fa-xmark fa-xl closeButton"></i>
          </div>
        </header>

        <div class="containerBody" :class="{ bodyAccept: step_connect == 3 }">
          <div>
            <Login_Sinao v-if="step_connect == 1" />
            <Select_app v-if="step_connect == 2" />
            <Accept_Connect v-if="step_connect == 3" @end="close" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import Vue from "vue";
import { mapState } from "vuex";
import Login_Sinao from "../../views/Login_Sinao.vue";
import Select_app from "../../views/Select_app.vue";
import Accept_Connect from "../../views/Accept_Connect.vue";

export default {
  name: "Modal_ConnectSinao",
  props: ["document"],
  data() {
    return {
      step: 1,
    };
  },
  components: {
    Login_Sinao,
    Select_app,
    Accept_Connect,
  },
  computed: {
    ...mapState(["step_connect", "user", "bearer"]),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  async created() {
    const cookieArray = document.cookie.split("; ");
    for (const cookie of cookieArray) {
      const [name, value] = cookie.split("=");

      if (name == "token" || name == "ken") {
        this.$store.dispatch("setBearer", { bearer: value });
        break;
      }
    }

    if (this.bearer != null && this.bearer.length > 0) {
      await this.$store.dispatch("getUser").catch((error) => {
        console.log(error);
      });

      this.$store.dispatch("changeStep", { step: 2 });
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.header_login {
  width: 100%;
  text-align: center;
}

.h4,
h4 {
  font-size: 20px;
  font-family: "Inter";
  line-height: 1.2;
  color: black;
  font-weight: bold;
}

.bodyAccept {
  margin-bottom: 40px !important;
}

.modal_login {
  max-width: 800px !important;
  width: 100% !important;
}

@media (min-width: 1000px) {
  .modal_login {
    /* max-width: 900px !important;
    width: 100% !important; */
  }
}
</style>
