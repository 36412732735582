import Vue from "vue";
import App from "./App.vue";

import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css";
import "vuesax/dist/vuesax.css";
Vue.use(Vuesax);

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate, {
  mode: "eager",
});

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
Vue.config.productionTip = false;
Vue.use(VueToast, {
  position: "bottom-left",
  duration: 10000,
});

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
  timeout: 5000,
  draggable: false,
  closeOnClick: false,
  hideProgressBar: true,
};
Vue.use(Toast, options);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

require("./assets/css/iconfont.css");
import store from "./store/store";

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
